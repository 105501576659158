import React, { useEffect, useState } from 'react'
import {
    selectIsConnectedToRoom,
    useHMSActions,
    useHMSStore,
    HMSRoomProvider,
    useVideo,
    useAVToggle,
} from "@100mslive/react-sdk"
import { Box, Button, Flex, Heading } from '@chakra-ui/react'
import "./style.css";
import Header from './Header'
import Footer from './Footer'
import Conference from './Conference'

export default function HMSServiceContent({ roomCode, user }) {

    const [roomId] = useState(roomCode);
    const hmsActions = useHMSActions();
    const isConnected = useHMSStore(selectIsConnectedToRoom);

    const [hostAuthToken, setAuthToken] = useState("");

    useEffect(() => {
        handleJoin();
    }, []);

    const handleJoin = async () => {
        const authToken = await hmsActions.getAuthTokenByRoomCode({
            roomCode: roomId,
        });
        setAuthToken(authToken);
        try {
            await hmsActions.join({
                userName: user?.name,
                authToken,
            });
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <div className="p-4" style={{
            display: "flex",
            flexDirection: "column",
            height: "100%"
        }}>
            {isConnected && (
                <>
                    {/* <Header /> */}
                    <Conference />
                    <Footer hostAuthToken={hostAuthToken} />
                </>
            )}
        </div>
    )
}
