import { Box, Center } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { requestUserProfileAction } from '../../redux/reducers/user';

export const ThreeD = () => {
    const dispatch = useDispatch();

    const iframeRef = useRef(null);
    const [credentials, setCredentials] = useState()
    const [filterCredential, setFilterCredential] = useState(null)

    useEffect(() => {
        let filteredDATA = credentials?.find(obj => obj.app === "3D")
        setFilterCredential(filteredDATA)
    }, [credentials])

    const { user } = useSelector((state) => ({
        user: state.user.user,
    }))
    useEffect(() => {
        dispatch(requestUserProfileAction());
        return () => {
            const iframe = document.getElementById('myIframe');
            console.log(iframe);
            const message = {
                type: "logout",
                client: "rankplus"
            };
            iframe?.contentWindow?.postMessage('*', message);
        }
    }, [])

    useEffect(() => {



        if (user && user?.staff?.thirdPartyCredentials !== undefined && user?.staff?.thirdPartyCredentials?.length > 0) {
            setCredentials(user.staff.thirdPartyCredentials)
        }
    }, [user])


    function sendMessageToIframe() {
        const iframe = document.getElementById('myIframe');
        console.log("logoutIframe", iframe)
        const message = {
            type: "logout",
            client: "rankplus"
        };

        iframe?.contentWindow?.postMessage(message, '*');
    }
    function sendMessageToIframeOne() {
        const iframe = document.getElementById('myIframe');
        console.log("logout11Iframe", iframe);
        const message = {
            type: "logout",
            client: "rankplus"
        };

        iframe?.contentWindow?.postMessage('*', message);
    }

    return (
        <div className='h-100'>
            {
                filterCredential ?
                    <>
                        <button className="me-4" onClick={sendMessageToIframe}>logout</button>
                        <button onClick={sendMessageToIframeOne}>logout1</button>
                        <iframe
                            id='myIframe'
                            ref={iframeRef}
                            src={`https://d1xmxgiyxdj9dj.cloudfront.net/#/login?email=${filterCredential?.username}&password=${filterCredential?.password}`}
                            width="100%"
                            height="100%"
                            title="Login"
                        />
                    </>
                    :
                    <Center color={"brand.secondary"} height="100vh">
                        Contact with admin for the credentials access
                    </Center>
            }
        </div>
    );
};