import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import _, { map } from "lodash";
import { Box, Heading } from "@chakra-ui/layout";
import {
    Divider,
    Flex,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    useToast,
} from "@chakra-ui/react";

import MentorshipClasses from "./MentorshipClasses";
import {
    resetMentorFeedbackStatus,
    getMentorEventsListAction,
    resetEndMentorshipSessionStatus,
    resetStartMentorshipSessionStatus
} from "../../redux/reducers/mentorship";
import { STATUS } from "../../App/Constants";
import { Feedback } from "../../Components/Feedback";
import { HMSRoomProvider } from "@100mslive/react-sdk";
import HMSServiceContent from "../../Components/HMSService";


const weekDays = ["S", "M", "T", "W", "T", "F", "S"];

export default function MentorshipScreen() {
    const toast = useToast();
    const dispatch = useDispatch();

    const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD"));
    const [selectedDate, setSelectedDate] = useState(moment());
    const [currentTeacherId, setCurrentTeacherId] = useState("");
    const [roomCode, setRoomCode] = useState("");
    const [feedbackModal, setFeedbackModal] = useState(false);
    const [LiveSessionStatus, setLiveSessionStatus] = useState(false);

    const { user, mentorshipSessionStartData, endMentorshipSessionStatus, startMentorshipSessionStatus, sessionCompleted, mentorFeedbackStatus } = useSelector((s) => ({
        user: s.user.user,
        mentorEventsList: s.mentorship.mentorEventsList,
        mentorFeedbackStatus: s.mentorship.mentorFeedbackStatus,
        mentorshipSessionStartData: s.mentorship.mentorshipSessionStartData,
        startMentorshipSessionStatus: s.mentorship.startMentorshipSessionStatus,
        endMentorshipSessionStatus: s.mentorship.endMentorshipSessionStatus,
        sessionCompleted: s.mentorship.sessionCompleted,
    }));

    useEffect(() => {
        if (startMentorshipSessionStatus === STATUS.SUCCESS) {
            if (mentorshipSessionStartData !== undefined && mentorshipSessionStartData?.data?.length > 0) {
                const getMentorData = mentorshipSessionStartData?.data?.filter((data) => data?.role === "host")
                setRoomCode(getMentorData[0]?.code);
                setLiveSessionStatus(true)
                dispatch(resetStartMentorshipSessionStatus());
            } else if (mentorshipSessionStartData?.code !== undefined && mentorshipSessionStartData?.message) {
                toast.closeAll();
                toast({
                    title: "Your session is ended.",
                    position: "top",
                    variant: "subtle",
                    status: "error",
                    duration: 2500,
                    isClosable: false,
                });
            }
        }
    }, [startMentorshipSessionStatus]);


    useEffect(() => {
        if (endMentorshipSessionStatus === STATUS.SUCCESS) {
            dispatch(getMentorEventsListAction({ staffId: user?.staff?._id, date: currentDate }));
            dispatch(resetEndMentorshipSessionStatus());
            setLiveSessionStatus(false)
            sessionCompleted === true && setFeedbackModal(true);
        }

        if (mentorFeedbackStatus === STATUS.SUCCESS) {
            setFeedbackModal(false);
            dispatch(resetMentorFeedbackStatus());
        }
    }, [endMentorshipSessionStatus, mentorFeedbackStatus])

    const currTeacherId = (id) => { setCurrentTeacherId(id) }



    return (
        <Box mt={2} mx={2} p={3} style={{ border: "1px solid #e2e8f0", borderRadius: "12px", background: LiveSessionStatus === true ? "black" : "", height: "100%" }}>

            {LiveSessionStatus === false && <Box px={0} pt={6} maxH='auto' overflowY='scroll' pb={110} overflowX='hidden' pos='relative'>
                <Flex>
                    <Box w={{ md: '40%' }} display={{ base: 'none', lg: 'block' }} pr={1}>
                        <ScheduleClasses setSelectedDate={setSelectedDate} selectedDate={selectedDate} currentTeacherId={currentTeacherId} />

                    </Box>
                    <MentorshipClasses selectedDate={selectedDate} currentTeacherId={(id) => currTeacherId(id)} />
                </Flex>
            </Box>}

            {LiveSessionStatus === true &&
                <HMSRoomProvider>
                    <HMSServiceContent roomCode={roomCode} user={user} />
                </HMSRoomProvider>}



            <Modal isOpen={feedbackModal} size='lg'
            // onClose={() => setFeedbackModal(false)}
            >
                <ModalOverlay />
                <ModalContent>
                    {/* <ModalHeader>Feedback</ModalHeader> */}
                    <Divider />
                    <ModalBody>
                        <Feedback
                            setFeedbackModal={setFeedbackModal} lectureId={user?.lectureFeedbacks[0]?.lectureId?._id}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal >
        </Box >
    )
}

export const ScheduleClasses = ({ setSelectedDate, selectedDate, currentTeacherId }) => {
    const dispatch = useDispatch();
    const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD"));

    const { user, getMentorEventsStatus, mentorEventsList } = useSelector((s) => ({
        getMentorEventsStatus: s.mentorship.getMentorEventsStatus,
        mentorEventsList: s.mentorship.mentorEventsList || [],
        user: s.user.user,
    }));

    useEffect(() => {
        if (user?._id) {
            dispatch(getMentorEventsListAction({ staffId: user?.staff?._id, date: currentDate }));
        }
    }, [user, currentTeacherId]);

    return (
        <>
            <Box>
                <Flex align={"stretch"}>
                    <Box flex={10}>
                        <Box>
                            <Box px={[1, 1, 1, 5]}>
                                <Box
                                    className="overflow-auto text-center events-heading-block"
                                    py={2}
                                    px={1}
                                    // height={{ base: "70dvh", sm: "80dvh", md: "81dvh", lg: "60dvh", xl: "60dvh" }}
                                    style={{ height: "max-content" }}
                                    borderRadius={5}
                                    boxShadow="rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"
                                >
                                    CALENDAR
                                </Box>
                                <Box
                                    className="overflow-auto "
                                    py={2}
                                    px={1}
                                    // height={{ base: "70dvh", sm: "80dvh", md: "81dvh", lg: "60dvh", xl: "60dvh" }}
                                    style={{ height: "max-content" }}
                                    borderRadius={5}
                                    boxShadow="rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"
                                >
                                    <Box py={4} bg="white"
                                        //  border="1px solid"
                                        borderColor="gray.200">
                                        <Box px={4}>
                                            <Heading size="sm">Upcoming Events</Heading>
                                            <Flex mt={6} align="center" justify="space-between">
                                                <Box>{moment(selectedDate).format("MMM YYYY")}</Box>
                                                <DateButton
                                                    user={user}
                                                    selectedDate={selectedDate}
                                                    setSelectedDate={setSelectedDate}
                                                />
                                            </Flex>
                                        </Box>
                                        <Box mt={2}>
                                            <ClassDates
                                                setSelectedDate={setSelectedDate}
                                                selectedDate={selectedDate}
                                                mentorEventsList={mentorEventsList}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Flex>

            </Box>

        </>
    );
};

const DateButton = ({ user, selectedDate, setSelectedDate }) => {
    const dispatch = useDispatch();

    const handleDateChange = (d) => {
        // Extract month and year from the current and new date
        const newMonth = moment(d).format("MM");
        const newYear = moment(d).format("YYYY");
        const currentMonth = moment(selectedDate).format("MM");
        const currentYear = moment(selectedDate).format("YYYY");

        if (currentMonth !== newMonth || currentYear !== newYear) {
            dispatch(getMentorEventsListAction({ staffId: user?.staff?._id, date: moment(d).format("YYYY-MM-DD") }));
            // Perform the desired action
        }
        setSelectedDate(d);
    }

    return (
        <Box>
            <Input
                borderColor="transparent"
                value={moment(selectedDate).format("YYYY-MM-DD")}
                onChange={(e) => handleDateChange(moment(e.target.value))}
                w={55}
                type="date"
            />
        </Box>
    );
};

const ClassDates = ({ selectedDate, setSelectedDate, mentorEventsList }) => {
    const [dates, setDates] = useState([]);
    const [events, setEvents] = useState([]);

    useEffect(() => {
        if (selectedDate) {
            let newDates = [];
            const firstDateOfMonth = moment(selectedDate).set("date", 1);
            const endDateOfMonth = moment(firstDateOfMonth)
                .add(1, "month")
                .subtract(1, "day");
            let currentDate = firstDateOfMonth;

            let gapDates = firstDateOfMonth.get("day");

            while (gapDates > 0) {
                newDates.push(null);
                gapDates -= 1;
            }
            while (currentDate <= endDateOfMonth) {
                newDates.push(currentDate);
                currentDate = moment(currentDate).add(1, "days");
            }
            setDates(newDates);
        }
    }, [selectedDate]);

    useEffect(() => {
        if (mentorEventsList && mentorEventsList?.length > 0) {
            setEvents(mentorEventsList);
        } else if (!mentorEventsList) {
            setEvents([])
        }
    }, [mentorEventsList]);

    const handleDateChange = (d) => {
        // Extract month and year from the current and new date
        const newMonth = moment(d).format("MM");
        const newYear = moment(d).format("YYYY");
        const currentMonth = moment(selectedDate).format("MM");
        const currentYear = moment(selectedDate).format("YYYY");


        if (currentMonth !== newMonth || currentYear !== newYear) {
            // Perform the desired action
        }
        setSelectedDate(d);
    }
    return (
        <Box w="100%" px={1}>
            <Flex w="100%" wrap="wrap">
                {map(weekDays, (d, i) => (
                    <Box
                        fontSize="xs"
                        textAlign="center"
                        fontWeight="bold"
                        color="gray.500"
                        p={2}
                        minW={100 / weekDays.length + "%"}
                        maxW={100 / weekDays.length + "%"}
                        key={i}
                    >
                        {d}
                    </Box>
                ))}

                {map(dates, (d, i) => {
                    if (d) {
                    }
                    const isSelected = d &&
                        d.format("YYYY-MM-DD") === moment(selectedDate).format("YYYY-MM-DD");

                    const hasClasses = d && events?.find((e) => {
                        return moment(e.startDate).format("YYYY-MM-DD") === d.format("YYYY-MM-DD")
                    }
                    );

                    return (
                        <Box
                            cursor="pointer"
                            onClick={() => d && handleDateChange(d)}
                            pos="relative"
                            borderRadius={5}
                            bg={isSelected ? "blue.400" : "inherit"}
                            fontSize="sm"
                            textAlign="center"
                            fontWeight="bold"
                            color={isSelected ? "white" : "gray.800"}
                            p={2}
                            minW={100 / weekDays.length + "%"}
                            maxW={100 / weekDays.length + "%"}
                            key={i}
                        >
                            <Box key={i}>{d ? d.format("DD") : ""}</Box>
                            {hasClasses && d && (
                                <Box
                                    w={2}
                                    h={2}
                                    borderRadius={10}
                                    bg={isSelected ? "white" : "blue.400"}
                                    pos="absolute"
                                    left="50%"
                                    transform="translateX(-50%)"
                                    bottom={"2px"}
                                >
                                </Box>
                            )}
                        </Box>
                    );
                })}
            </Flex>
        </Box>
    );
};